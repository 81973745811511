import { Paper, Typography, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import Auth from "../components/Auth";
import ExpertPicks from "../components/ExpertPicks";
import Page from "../components/Page";
import Picks from "../components/Picks";
import Score from "../components/Score";
import Progress from "../components/Progress";
import Welcome from "../components/Welcome";
import State from "../State";
let counter = 0;

const styles = (theme) => ({
  paper: {
    overflowX: "auto",
  },
});

class EnhancedTable extends Component {
  render() {
    const classes = this.props.classes;

    return (
      <Page
        isRightSticky={true}
        right={
          <>
            {false && State.user.loggedIn && State.league.isLocked && <Score />}
            {!State.league.isLocked && <Progress />}
            <ExpertPicks />
            <Paper style={{ marginTop: 16 }} className={classes.paper}>
              {State.user.loaded && !State.user.loggedIn && (
                <div style={{ marginTop: 10 }}>
                  <Typography type="subheading">
                    <b>Sign in to save your picks!</b>
                  </Typography>
                </div>
              )}
              <Auth />
            </Paper>
          </>
        }
      >
        {State.user.loaded && !State.user.loggedIn && (
          <Welcome
            title={"Welcome to Win Totals!"}
            body={
              <>
                {" "}
                <p>
                  Fill in the number of games you think each team will win this
                  season in the empty standings. Then, create or join a league
                  to compete against other league members to see whose
                  predictions were closest at the end of the season. We'll help
                  keep you on track by ensuring you end up with an even balance
                  of wins and losses across the NBA.
                </p>
                <p>
                  Don't know where to begin? Try starting with last year's standings and adjust from there. Last day to make predictions is Tuesday, 10/22. Good luck!
                </p>
              </>
            }
          />
        )}

        <Picks />
      </Page>
    );
  }
}

export default withStyles(styles)(observer(EnhancedTable));
